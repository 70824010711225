<template>
  <FocusTrap>
  <div class="card">

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 10px;" >
      <h4>
        <span class="font-weight-semibold">HSN Purchase Invoice</span> Information
      </h4>
    </div>



    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Supplier</label>
            <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.ledger.code" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">

        </div>


        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Invoice No</label>
            <input type="text" id="txtrefno" class="form-control" placeholder="Invoice No" maxlength="30" v-if="voucher" v-model="voucher.ref_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Invoice Date</label>
            <input id="txtrefdate" type="date" class="form-control" v-if="voucher" v-model="voucher.ref_date" placeholder="Invoice Date" >
          </div>
        </div>

      </div>



      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="width:125px;">HSN</th>
              <th >Description</th>
              <th style="width:75px; text-align: right;">Tax %</th>
              <th style="width:100px; text-align: right;">Price</th>
              <th style="width:75px; text-align: right;">Qty</th>
              <th style="width:100px; text-align: right;">Taxable</th>
              <th style="width:100px; text-align: right;">GST</th>
              <th style="width:150px; text-align: right;">Net Amount</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody  >

            <tr v-model="voucher" v-for="(detail,index) in voucher.list">
              <td style="text-align: center;"> {{index + 1}} </td>
              <td style="width: 125px;">
                <!--<select class="form-control" v-if="detail" v-model="detail.item.code" @change="rowValueEqualizer(index, 1)" >-->
                  <!--<option v-for="item in detailItems" v-bind:value="item.code">-->
                    <!--{{ item.code }} - {{ item.name }}-->
                  <!--</option>-->
                <!--</select>-->
                <input type="text" class="form-control" placeholder="HSN No" maxlength="9"  v-if="detail" v-model="detail.hsn" />
              </td>

              <td >
                <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />
              </td>

              <td >
                <select class="form-control text-right" v-if="detail" v-model="detail.gst_rate" @change="rowValueEqualizer(index, 3)" >
                  <option value="0"> 0</option>
                  <option value="0.1">0.1</option>
                  <option value="0.25">0.25</option>
                  <option value="1"> 1</option>
                  <option value="3"> 3</option>
                  <option value="5"> 5</option>
                  <option value="12">12</option>
                  <option value="18">18</option>
                  <option value="28">28</option>
                </select>
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Price / Unit" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 4)" />
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 5)"/>
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 6)"/>
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.tax_amt" readonly />
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
              </td>

              <td class="text-center">
                <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
              </td>
            </tr>

            </tbody>
            <tfoot>
            <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span></td>

              <td></td>


            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <h3 style="font-size: 48px;color: blue">
            <!--voucher.gross_amount-->
            &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(voucher.round_off) ) }}</span>
          </h3>
        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">
            <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
            <div class="col-md-8">
              <input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off" >
            </div>
          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import numeral from 'numeral'

  export default {
    name: 'HsnInvoiceForm',
    store,
    components: {

    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        detailItems:[],
        items : new Map(),
        rowAmountTotal: 0.0,
        voucher: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        seriesid: 1004
      }
    },
    created () {
      let self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

    },
    mounted () {
      let self = this;
      self.loadData();

      if (self.$data.voucher.doc_date === '0001-01-01'){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }

      $('.form-control-select2').select2();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      addRow(){
        try {
          let self = this;
          self.$data.voucher.list.push(JSON.parse('{"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_code":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"hsn":"","item":{"id":"","code":1,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","process":{"id":"","code":1,"name":"","nature":0,"p_name":"","sys_obj":false},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":"","code":1,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","wgt":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'));
          var myobj = $('table>tbody>tr:last>td:nth-child(2)').children();
          $(myobj).focus();

        }catch (e) {
          alert(e);
        }
      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;

        let detail = self.$data.voucher.list[rowIndex];
        switch (colIndex) {
          case 1:
            detail.item = self.$data.items.get(detail.item.code);
            break;
          case 3: //Tax Rate
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
            detail.tax_amt = numeral(detail.taxable_amt * (detail.gst_rate/100)).format('0.00');
            detail.net_amt = numeral(detail.taxable_amt + parseFloat(detail.tax_amt)).format('0.00');

            break;
          case 4: //Product Rate
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
            detail.tax_amt = numeral(parseFloat(detail.taxable_amt) * (detail.gst_rate/100)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');

            break;
          case 5: //Product Qty
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
            detail.tax_amt = numeral(parseFloat(detail.taxable_amt) * (detail.gst_rate/100)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            //detail.net_amt = numeral(detail.net_amt).format('0.00');
            break;
          case 6: //Taxable value
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.taxable_amt = parseFloat(detail.taxable_amt);
            detail.rate = numeral(detail.taxable_amt / detail.qty).format('0.00');
            detail.tax_amt = numeral(parseFloat(detail.taxable_amt) * (detail.gst_rate/100)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');

            break;
        }
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
        });
      },

      sumRowAmountTotal() {
        let self = this;

        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.gross_amount = 0.0;

        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.voucher.gross_amount += parseFloat(detail.net_amt);
        });
      },
      clear(){
        let self = this;
        self.$data.voucher = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/supplier/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.ledgers = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        //Fetch Detail Ledgers
        self.$data.detailItems = []
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(resp.data != null){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (itm) {
                self.$data.items.set(itm.code, itm);
              });
            }

          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.details.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        let self = this;

        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.finyear = store.state.user.finyear;
        myvoucher.series = 312;
        myvoucher.is_cr = "Y";
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myvoucher.ref_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myvoucher.eway_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myvoucher.round_off = parseFloat(self.$data.voucher.round_off);
        myvoucher.gross_amount = self.$data.rowAmountTotal;
        myvoucher.net_amount = myvoucher.gross_amount + myvoucher.round_off;
        myvoucher.process.code =1;
        myvoucher.ibr = localStorage.getItem('branch_code');

        myvoucher.list.forEach(function (detail){

          if(detail.hsn.length == 0){
            alert('Invalid HSN Entered');
            return ;
          }

          detail.order_no = 1;//parseInt(detail.order_no);
          detail.work_order = 1;//parseInt(detail.work_order);
          detail.item.code =1;
          detail.qty_txt = detail.qty.toString();
          detail.qty = parseFloat(detail.qty);
          detail.unit.code = 1;
          detail.rate = parseFloat(detail.rate);
          detail.taxable_amt = parseFloat(detail.taxable_amt);
          detail.tax_amt = parseFloat(detail.tax_amt);
          detail.net_amt = parseFloat(detail.net_amt);
        });

        const requestOptions = {
          method: 'POST',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            swal({
              title: 'Great',
              type: 'success',
              text: resp.message
            });
            self.clear();
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
